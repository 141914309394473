<template>
  <div id="app">
    <h1>Core Application</h1>
    <p v-if="loading">Loading...</p>
    <p v-else-if="error">{{ error }}</p>
    <p v-else>Data received: {{ responseData }}</p>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

export default {
  setup() {
    const responseData = ref(null)
    const loading = ref(true)
    const error = ref(null)

    // Получаем CSRF-токен
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    // Устанавливаем CSRF-токен в заголовки по умолчанию
    axios.defaults.headers.common['X-CSRFToken'] = csrfToken

    onMounted(() => {
      window.Telegram.WebApp.ready();

      const initData = window.Telegram.WebApp.initData;
      console.log(initData);
      console.log(initData.query_id);
      console.log(initData.user)

      // Отправляем данные на серверную часть
      axios.post('/core/api/data/', {
        initData
      })
      .then(response => {
        responseData.value = response.data.message
        loading.value = false
      })
      .catch(err => {
        error.value = 'Error occurred while sending data.'
        loading.value = false
        console.log(err)
      })
    })

    return {
      responseData,
      loading,
      error,
    }
  },
}
</script>

<style>
</style>
